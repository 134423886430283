<template>
  <form
    id="app"
    v-on:submit.prevent="SalvarFormularioSolicitacao()"
    method="post"
  >
    <b-card-code title="Formulario de Solicitação">
      <b-row v-if="solidesenvolvimentoid > 0">
        <b-col md="6" xl="2" class="mb-1">
          <b-form-group label="Nº Solicitação:" >
            <b-form-input
              id="numsoliproduto"
              placeholder=""
              v-model="numsoliproduto"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" xl="5" class="mb-1">
          <b-form-group label="Produto:" >
            <b-form-input
              id="produtoInput"
              placeholder=""
              v-model="produto"
              required
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="2" class="mb-1">
          <b-form-group label="Peso(KG):" >
            <b-form-input
              id="pesoInput"
              placeholder=""
              v-model="peso"
              required
              type="number"
              step="0.01"
              min="0.00"
              v-model.number="peso"
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="5" class="mb-1">
          <b-form-group label="Cliente:" >
            <b-form-input
              id="clienteInput"
              placeholder=""
              v-model="cliente"
              required
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" xl="5" class="mb-1">
          <b-form-group
            label="Aplicação final do produto:"
            
          >
            <b-form-textarea
              id="aplicacaofinalInput"
              placeholder=""
              v-model="aplicacaofinal"
              rows="3"
              required
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="2" class="mb-1">
          <b-form-group label="Mercado:" >
            <div v-for="Mercado in Mercados" :key="Mercado">
              <input
                type="radio"
                :disabled="PermiteSalvar ? false : ''"
                name="mercado"
                :id="Mercado"
                :value="Mercado"
                v-model="mercado"
                required
              />
              {{ Mercado }}
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" xl="2" class="mb-1">
          <b-form-group
            label="Tipo Desenvolvimento:"
            
          >
            <div
              v-for="TipoDesenvolvimento in TipoDesenvolvimentos"
              :key="TipoDesenvolvimento"
            >
              <input
                type="radio"
                name="tipodesenvolvimento"
                :disabled="PermiteSalvar ? false : ''"
                :id="TipoDesenvolvimento"
                :value="TipoDesenvolvimento"
                v-model="tipodesenvolvimento"
                required
              />
              {{ TipoDesenvolvimento }}
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6" xl="1" class="mb-1">
          <b-form-group label="Restrição:" >
            <div>
              <input
                name="retricao"
                type="radio"
                id="Nao"
                value="0"
                :disabled="PermiteSalvar ? false : ''"
                v-model="restricao"
                required
              />
              Não
            </div>
            <div>
              <input
                name="retricao"
                type="radio"
                id="Sim"
                value="1"
                :disabled="PermiteSalvar ? false : ''"
                v-model="restricao"
                required
              />
              Sim
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="restricao == '1'">
        <b-col md="6" xl="5" class="mb-1">
          <b-form-group
            label="Descreva a Restição ou Anexe o Documento:"
            
          >
            <b-form-textarea
              id="restricaodescInput"
              placeholder=""
              v-model="restricaodesc"
              rows="6"
              required
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="6" class="mb-1">
          <b-row>
            <b-col md="6" xl="8" class="mb-1" v-if="PermiteSalvar">
              <b-form-file
                truncate-length="50"
                accept=".jpeg, .png, .pfd, .xls, .xlsx, .doc, .docx"
                placeholder="Selecione o arquivo..."
                @change="carregarDocRestricao"
                v-model="DocRestricao"
                ref="DocRestricao-input"
              ></b-form-file>
            </b-col>
            <b-col md="6" xl="2" class="mb-1">
              <b-button
                variant="primary"
                v-if="PermiteSalvar"
                type="button"
                @click="salvarDocumento"
                ><span>ADICIONAR</span></b-button
              >
            </b-col>
          </b-row>
          <b-table
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="ListDocRestricao"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            table-class = "font-size11"
            thead-class = "font-size11"
            thead-tr-class = "font-size11"
          >
            <template #cell(id)="row">
              <div class="DivBotaoLadoLado">
                <b-button
                  size="sm"
                  @click="DownloadDocumento(row.value)"
                  class="mr-1"
                >
                  BAIXAR
                </b-button>
              </div>
              <div class="DivBotaoLadoLado">
                <b-button
                  v-if="PermiteSalvar"
                  variant="danger"
                  size="sm"
                  @click="RemoverDocumento(row.value)"
                  class="mr-1"
                >
                  REMOVER
                </b-button>
              </div>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Registros por Pagina"
              label-cols="8"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Volume estimado (kg/mês):"
            
          >
            <b-form-input
              id="volumeestimadoInput"
              v-model="volumeestimado"
              required
              type="number"
              step="0.01"
              min="0.00"
              v-model.number="volumeestimado"
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group label="Preço alvo (R$/kg):" >
            <b-form-input
              id="precoalvoInput"
              placeholder=""
              v-model="precoalvo"
              required
              type="number"
              step="0.01"
              min="0.00"
              v-model.number="precoalvo"
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Prazo para enviar amostra:"
            
          >
            <b-form-datepicker
              id="prazoesperadoenvioInput"
              locale="pr-BR"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              today-button
              reset-button
              v-model="prazoesperadoenvio"
              :min="minData"
              placeholder="Selecione"
              :required="true"
              :disabled="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" xl="3" class="mb-1">
          <b-form-group
            label="Prazo para lançamento:"
            
          >
            <b-form-datepicker
              id="prazoesperadolancamentoInput"
              locale="pr-BR"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              today-button
              reset-button
              v-model="prazoesperadolancamento"
              :min="minData"
              placeholder="Selecione"
              :required="true"
              :disabled="PermiteSalvar ? false : ''"
            />
            <!-- :state="false" -->
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" xl="12" class="mb-1">
          <b-form-group
            label="Observações adicionais:"
            
          >
            <b-form-textarea
              id="observacoesadicionaisInput"
              placeholder=""
              v-model="observacoesadicionais"
              rows="4"
              required
              :readonly="PermiteSalvar ? false : ''"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>
    <b-card-code>
      <b-row>
        <b-col md="6" xl="4" class="mb-1">
          <div class="DivBotaoLadoLado">
            <b-button
              variant="danger"
              type="button"
              @click="$router.push('../DevProduto')"
            >
              <span>RETORNAR</span>
            </b-button>
          </div>
          <div class="DivBotaoLadoLado">
            <b-button variant="success" type="submit" v-if="PermiteSalvar">
              <span>SALVAR</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-code>
  </form>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BFormDatepicker, BButton,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormFile,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BTable,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormFile,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)

    return {
      PermiteSalvar: false,
      origem: 'FormularioSolicitacao_Restricao',
      Desenvolvimento: {
      },
      solidesenvolvimentoid: 0,
      numsoliproduto: '',
      produto: '',
      peso: '',
      cliente: '',
      aplicacaofinal: '',
      mercado: '',
      tipodesenvolvimento: '',
      restricao: '',
      restricaodesc: '',
      volumeestimado: '',
      precoalvo: '',
      prazoesperadoenvio: '',
      prazoesperadolancamento: '',
      observacoesadicionais: '',
      statusworkflowid: 0,
      acessoetapa: 0,
      ListDocRestricao: [],
      DocRestricao: null,
      DocRestricaoBase64: null,
      Mercados: ['Varejo', 'FS', 'Institucional'],
      TipoDesenvolvimentos: ['Marca Própria', 'Tailor Made', 'Campanha Sazonal', 'Venda Aberta'],
      minData: minDate,
      perPage: 2,
      pageOptions: [2, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'docnome', label: 'Documento', sortable: true },
        { key: 'id', label: 'Ações' },
      ],
    }
  },
  methods: {
    async SalvarFormularioSolicitacao() {
      let notification = ''
      let MsgErro = ''
      let Operacao = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoFormulario?CODUSU=${usuario.codusu}`
      if (this.solidesenvolvimentoid > 0) {
        Operacao = 'Alterado'
      } else {
        Operacao = 'Incluido'
      }
      this.CarregaDadosNoObjeto() // Carrega os Dados no Objeto para enviar no Parametro
      const ParametroJson = JSON.parse(JSON.stringify(this.Desenvolvimento))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.Desenvolvimento = response.data
          // this.DescarregaDadosDoObjeto()
          MsgErro = `Formulario ${this.Desenvolvimento.numsoliproduto} ${Operacao} com Sucesso`
          notification = { message: MsgErro, alert_type: 'success' }
          this.$session.set('notification', notification)
          // Faz a navegação para a Tela de Lista
          this.$router.push('../DevProduto')
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
    },
    async MostraFormularioSolicitacao($SOLIDESENVOLVIMENTOID) {
      let notification = ''
      const usuario = this.$session.get('usuario')
      const SOLIDESENVOLVIMENTOID = $SOLIDESENVOLVIMENTOID
      const ETAPA = 'FormularioSolicitacao'
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoMostra?CODUSU=${usuario.codusu}&SOLIDESENVOLVIMENTOID=${SOLIDESENVOLVIMENTOID}&ETAPA=${ETAPA}`
      await apiPortalSky.post(URLpost, SOLIDESENVOLVIMENTOID).then(response => {
        if (response.status === 200) {
          this.Desenvolvimento = response.data
          this.DescarregaDadosDoObjeto()
          this.ValidaAcesso()
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })
    },
    ValidaAcesso() {
      switch (this.statusworkflowid) {
        case 1:
          // Valida Se Usuario pode Editar
          if (this.acessoetapa === 1) {
            this.PermiteSalvar = true
          }
          break
        default:
          this.PermiteSalvar = false
          break
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    carregarDocRestricao(e) {
      const comp = this
      const img = e.target.files[0]
      const promise = new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = function () {
          resolve(reader.result)
        }
        reader.readAsDataURL(img)
      })
      promise.then(img2 => {
        comp.srcImg = img2
        this.DocRestricaoBase64 = comp.srcImg.replace('data:', '').replace(/^.+,/, '')
      })
    },
    salvarDocumento() {
      if (this.DocRestricao != null) {
        let maxId = 1
        if (this.ListDocRestricao.length > 0) {
          maxId += this.ListDocRestricao.reduce(
            (max, item) => (item.id > max ? item.id : max),
            this.ListDocRestricao[0].id,
          )
        }

        this.$refs['DocRestricao-input'].reset() // Limpa input
        const documento = {
          id: maxId,
          solidesenvolvimentoanexoid: 0,
          docnome: this.DocRestricao.name,
          extensao: this.DocRestricao.type,
          docimage: this.DocRestricaoBase64,
          origem: this.origem,
          tipooperacao: 'INCLUIR',
        }
        if (this.Desenvolvimento.listaSoliDesenvolvimentoAnexo == null) {
          this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
        }
        this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.push(documento)
        this.ListDocRestricao.push(documento)
        this.totalRows = this.ListDocRestricao.length
        this.DocRestricao = null
      }
    },
    async DownloadDocumento(id) {
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.forEach(Documento => {
        let notification = ''
        // Localiza Documento para Download
        if (Documento.id === id) {
          let DocumentoDownload = Documento
          if (Documento.solidesenvolvimentoanexoid != null) {
            if (Documento.solidesenvolvimentoanexoid !== '') {
              const usuario = this.$session.get('usuario')
              const SOLIDESENVOLVIMENTOID = Documento.solidesenvolvimentoid
              const SOLIDESENVOLVIMENTOANEXOID = Documento.solidesenvolvimentoanexoid
              const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoMostraAnexo?CODUSU=${usuario.codusu}&SOLIDESENVOLVIMENTOID=${SOLIDESENVOLVIMENTOID}&SOLIDESENVOLVIMENTOANEXOID=${SOLIDESENVOLVIMENTOANEXOID}`
              apiPortalSky.post(URLpost, SOLIDESENVOLVIMENTOANEXOID).then(response => {
                if (response.status === 200) {
                  DocumentoDownload = response.data
                  if (DocumentoDownload.docimage !== '') {
                    const link = document.createElement('a')
                    link.href = 'data:image;base64,'.concat(link.href.concat(DocumentoDownload.docimage))
                    link.download = DocumentoDownload.docnome
                    link.click()
                  }
                }
              }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
              })
            }
          } else if (DocumentoDownload.docimage !== '') {
            const link = document.createElement('a')
            link.href = 'data:image;base64,'.concat(link.href.concat(DocumentoDownload.docimage))
            link.download = DocumentoDownload.docnome
            link.click()
          }
        }
      })
    },
    RemoverDocumento(id) {
      const listaSoliDesenvolvimentoAnexo = [] // Lista Temporaria
      this.ListDocRestricao = []
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.forEach(Documento => {
        const DocumentoValidacao = Documento
        if (DocumentoValidacao.id !== id
         && DocumentoValidacao.tipooperacao !== 'REMOVER') {
          this.ListDocRestricao.push(DocumentoValidacao)
          listaSoliDesenvolvimentoAnexo.push(DocumentoValidacao)
        } else if (DocumentoValidacao.solidesenvolvimentoanexoid !== 0) {
          DocumentoValidacao.tipooperacao = 'REMOVER'
          listaSoliDesenvolvimentoAnexo.push(DocumentoValidacao)
        }
      })
      this.totalRows = this.ListDocRestricao.length
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = []
      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo = listaSoliDesenvolvimentoAnexo
    },
    CarregaDadosNoObjeto() {
      this.Desenvolvimento.solidesenvolvimentoid = this.solidesenvolvimentoid
      this.Desenvolvimento.numsoliproduto = this.numsoliproduto
      this.Desenvolvimento.produto = this.produto
      this.Desenvolvimento.peso = this.peso
      this.Desenvolvimento.cliente = this.cliente
      this.Desenvolvimento.aplicacaofinal = this.aplicacaofinal
      this.Desenvolvimento.mercado = this.mercado
      this.Desenvolvimento.tipodesenvolvimento = this.tipodesenvolvimento
      this.Desenvolvimento.restricao = this.restricao
      this.Desenvolvimento.restricaodesc = this.restricaodesc
      this.Desenvolvimento.volumeestimado = this.volumeestimado
      this.Desenvolvimento.precoalvo = this.precoalvo
      this.Desenvolvimento.prazoesperadoenvio = this.prazoesperadoenvio
      this.Desenvolvimento.prazoesperadolancamento = this.prazoesperadolancamento
      this.Desenvolvimento.observacoesadicionais = this.observacoesadicionais
      this.Desenvolvimento.statusworkflowid = this.statusworkflowid
      this.Desenvolvimento.acessoetapa = this.acessoetapa
    },
    DescarregaDadosDoObjeto() {
      this.solidesenvolvimentoid = this.Desenvolvimento.solidesenvolvimentoid
      this.numsoliproduto = this.Desenvolvimento.numsoliproduto
      this.produto = this.Desenvolvimento.produto
      this.peso = this.Desenvolvimento.peso
      this.cliente = this.Desenvolvimento.cliente
      this.aplicacaofinal = this.Desenvolvimento.aplicacaofinal
      this.mercado = this.Desenvolvimento.mercado
      this.tipodesenvolvimento = this.Desenvolvimento.tipodesenvolvimento
      this.restricao = this.Desenvolvimento.restricao
      this.restricaodesc = this.Desenvolvimento.restricaodesc
      this.volumeestimado = this.Desenvolvimento.volumeestimado
      this.precoalvo = this.Desenvolvimento.precoalvo
      this.prazoesperadoenvio = this.Desenvolvimento.prazoesperadoenvio
      this.prazoesperadolancamento = this.Desenvolvimento.prazoesperadolancamento
      this.observacoesadicionais = this.Desenvolvimento.observacoesadicionais
      this.statusworkflowid = this.Desenvolvimento.statusworkflowid
      this.acessoetapa = this.Desenvolvimento.acessoetapa

      this.Desenvolvimento.listaSoliDesenvolvimentoAnexo.forEach(Documento => {
        if (Documento.origem === this.origem) {
          this.ListDocRestricao.push(Documento)
        }
      })
    },
  },
  mounted() {
    if (this.$route.params.solidesenvolvimentoid != null) {
      this.MostraFormularioSolicitacao(this.$route.params.solidesenvolvimentoid)
    } else {
      this.PermiteSalvar = true
    }

    // Seta Qtd Total Documentos
    if (this.ListDocRestricao.length > 0) {
      this.totalRows = this.ListDocRestricao.length
    } else {
      this.totalRows = 0
    }
  },
}
</script>
